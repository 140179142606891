<template>
  <!--订单统计-->
  <div style="padding: 20px;">
    <div>
      <div class="title">
        {{ $route.meta.title }}
      </div>

      <!--搜索条件-->
      <el-card shadow="never" style="margin-top: 20px;">
        <div class="card">
          <div class="card_t">
            <div class="card_t_icon"></div>
            <div class="card_t_text">
              筛选条件
            </div>
          </div>
          <div class="card_b">
            <!-- 搜索表单 -->
            <el-form
                :model="where"
                label-width="90px"
                class="ele-form-search"
                @keyup.enter.native="reload"
                @submit.native.prevent>
              <el-row :gutter="15">
                <!--<el-col :span="6">-->
                <!--  <el-form-item label="发包方:">-->
                <!--    <el-input-->
                <!--        clearable-->
                <!--        v-model="where.facilitator_name"-->
                <!--        placeholder="请输入发包方"/>-->
                <!--  </el-form-item>-->
                <!--</el-col>-->
                <el-col :span="8">
                  <el-form-item label="下单时间:">
                    <el-date-picker
                      value-format="yyyy-MM-dd"
                      v-model="where.times"
                        type="daterange"
                        unlink-panels
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                    <!--<DatePicker v-model="where.order_time" :transfer="true" @on-change="datechange" format="yyyy-MM-dd" type="daterange" placement="bottom-end" placeholder="请选择"/>-->
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <div class="ele-form-actions" style="margin-left: 60px;">
                    <el-button
                        type="primary"
                        class="ele-btn-icon"
                        @click="QueryList">查询
                    </el-button>
                    <el-button @click="empty">清空</el-button>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </el-card>

      <!--数据表格-->
      <el-card shadow="never" style="margin-top: 20px;">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <!--<div class="Datalist_icon"></div>-->
              <!--<div class="Datalist_text">-->
              <!--  数据列表-->
              <!--</div>-->
              <!--<div class="Datalist-botton" style="margin-left: 20px">-->
              <!--  <el-button class="custom-button" @click="CreatePenaltyOrder()">-->
              <!--    &lt;!&ndash;el-icon-devops 是自己命名的&ndash;&gt;-->
              <!--    <i class="el-icon-xiadan" />-->
              <!--    <span style="vertical-align: middle">下单</span>-->
              <!--  </el-button>-->
              <!--</div>-->
            </div>
            <div>
              <!--<el-button>导出数据</el-button>-->
              <!--<el-button @click="ListSetup()">列表设置</el-button>-->
            </div>
          </div>

          <div>
            <el-row>
              <el-col :lg="24" :md="24">
                <div class="demo-monitor-title">
                  <!--{{ {saleroom: '销售', visits: '访问量'}[saleSearch.type] }}趋势-->
                </div>
                <!--<ele-chart-->
                <!--    ref="saleChart"-->
                <!--    style="height: 285px;"-->
                <!--    :option="saleChartOption"/>-->
                <div id="tubiao" style="width: 100%;height:400px;" v-if="showempty == false"></div>
              </el-col>
            </el-row>

            <el-empty description="暂无数据" v-if="showempty == true"></el-empty>
          </div>

          <!--<div class="card_b">-->
          <!--  &lt;!&ndash; 数据表格 &ndash;&gt;-->
          <!--  <my-table-->
          <!--      ref="myTable"-->
          <!--      :columns="columns"-->
          <!--      :tableData="List"-->
          <!--      :cellStyle="cellStyle"-->
          <!--      :headerCellStyle="headerCellStyle"-->
          <!--      :rowClassName="rowClassName"-->
          <!--      :total="total"-->
          <!--      :customsFromWhere="where"-->
          <!--      :loading="loading"-->
          <!--      @select="select"-->
          <!--      @ChangeSize="ChangeSize"-->
          <!--      @currentChange="currentChange"-->
          <!--  >-->
          <!--  </my-table>-->
          <!--</div>-->
        </div>
      </el-card>

      <!--列表设置弹窗-->
      <div class="dialog">
        <el-dialog
            title="列表设置"
            :visible.sync="ListSetupShow"
            width="60%"
            center
            :before-close="handleClose">
        <span>
           <PackageSortElement :AllParameters="columns"   @change="SortList" @show="dialogshow"></PackageSortElement>
        </span>
        </el-dialog>
      </div>
    </div>


  </div>
</template>

<script>

import * as echarts from 'echarts';

import EleChart from 'ele-admin/packages/ele-chart';

// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";
// 权限
import {ShowTable} from '@/utils'
// 引入的接口
import {
  getfacilitatorApi,
  set_statusApi
} from '@/api/custom'
import Log from "echarts/src/scale/Log";
import {order_statistics} from "@/api/orderModule";

export default {
  // 组件生效
  components: {
    PackageSortElement,
    EleChart,
  },
  data(){
    return{
      //空状态
      showempty:false,

      // 表格搜索条件
      where: {
        // page: 1, // 当前页数
        // limit: 5 // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "车牌号",
          // type: "index",
          prop: "id",
          fixed:'left',
          isShow: true
        },
        {
          label: "报案号/订单号",
          prop: "facilitator_code",
          isShow: true
        },
        {
          label: "项目",
          prop: "facilitator_name",
          isShow: true
        },
        {
          label: "下单时间",
          prop: "status",
          isShow: true
        },
        {
          label: "状态",
          prop: "status_label",
          isShow: true
        },
        {
          label: "服务人员",
          prop: "id",
          isShow: true
        },
        {
          label: "订单来源",
          prop: "id",
          isShow: true
        },
        {
          label: "发包方",
          prop: "id",
          isShow: true
        },
        {
          label: "项目金额",
          prop: "id",
          isShow: true
        },
        {
          label: "支出金额",
          prop: "id",
          isShow: true
        },
      ],
      // 列表设置弹窗
      ListSetupShow:false,

      // 销售量搜索参数
      saleSearch: {
        type: 'saleroom',
        dateType: 0,
        datetime: ''
      },
      // 销售量数据
      saleroomData: [],

    }
  },

  computed:{
    // 销售额柱状图配置
    saleChartOption() {
      return {
        tooltip: {
          trigger: 'axis'
        },
        xAxis: [
          {
            type: 'category',
            data: this.saleroomData.map(d => d.month)
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            type: 'bar',
            data: this.saleroomData.map(d => d.value)
          }
        ]
      };
    },
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    this.getSaleroomData();

    //调用获取客户信息列表方法
    // this.getList();
  },

  methods:{
    // 点击处罚按钮按钮
    CreatePenaltyOrder(){
      // 显示创建订单模块
      this.showEdit = true;
    },

    // 获取列表
    getList(){
      getfacilitatorApi(this.where).then(async (res) => {
        this.total = res.data.count;
        // this.List = res.data.list;
        this.loading = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
      })
    },

    //选择时间
    datechange(e){
      this.where.order_time = e;
    },

    // 查询条件
    QueryList(){
      this.getSaleroomData();
    },
    // 清空
    empty(){
      this.where = {
        // page: 1, // 当前页数
        // limit: 5 // 每页显示条目个数
      }
      // this.getList();
      this.getSaleroomData();
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 仅对 type=selection 的列有效，Function(row, index) 用来决定这一行的 CheckBox 是否可以勾选
    selectable(row, index) {
      if (row.sex == "女") {
        return false
      } else {
        return true
      }
    },

    // 删除
    del(row){
      console.log(row);
      this.$utils.confirm("确定要删除吗？", function () {
        // 执行一些需要的逻辑
        console.log('点击确定')
      }, "确定", 'warning');
    },

    // 点击列表设置
    ListSetup(){
      this.ListSetupShow = true;
    },
    // 列表设置关闭
    handleClose(){
      this.ListSetupShow = false;
    },
    //事件  拿到从组件内部处理的数据顺序并赋值
    SortList(value){
      // 接收子组件传递的值替换当前列表显示顺序
      this.columns = value;
    },
    dialogshow(value){
      this.ListSetupShow = value;
    },

    /* 获取销售量数据 */
    getSaleroomData() {
      order_statistics(this.where).then(res => {
        console.log(res)
        console.log(res.data.series.length)
        let shuju = ['product', '项目金额', '支出金额'];
        res.data.series.unshift(shuju);
        console.log(res.data.series,'数据')
        var chartBox = this.$echarts.init(document.getElementById("tubiao"));
        const option = {
          color: ['#5087EC', '#68BBC4'],
          legend: {},
          tooltip: {},
          dataset: {
            // source: [
            //   ['product', '项目金额', '支出金额'],
            //   ['Matcha Latte', 43.3, 85.8],
            //   ['Milk Tea', 83.1, 73.4],
            //   ['Cheese Cocoa', 86.4, 65.2],
            //   ['Walnut Brownie', 72.4, 53.9]
            // ]
            source: res.data.series
          },
          xAxis: { type: 'category' },
          yAxis: {},
          // Declare several bar series, each will be mapped
          // to a column of dataset.source by default.
          series: [{ type: 'bar' }, { type: 'bar' }]
        }

        chartBox.setOption(option);
        // 根据页面大小自动响应图表大小
        window.addEventListener("resize", function () {
          chartBox.resize();
        });

        // if(res.data.series.length == 1 || res.data.series.length == 0){
        //   this.showempty = true;
        // }else {
        //   this.showempty = false;
        // }
        //
        // console.log(this.showempty)
      }).catch(res => {
        this.$message.error(res.msg);
      })

      // if (this.saleSearch.type === 'saleroom') {
      //   order_statistics().then(res => {
      //     console.log(res)
      //     // this.saleroomData = res.data.data;
      //     res.data.xAxis.forEach((item, index) => {
      //       this.saleroomData.push({
      //         month: item,
      //         value: ''
      //       })
      //     })
      //
      //     // this.saleroomData.forEach(item => {
      //     //   res.data.series.forEach(item2 => {
      //     //     item.value = item2;
      //     //   })
      //     // })
      //     res.data.series.forEach((item,index) => {
      //       this.saleroomData[index].value = item;
      //     })
      //
      //     console.log(this.saleroomData)
      //     // this.saleroomData = [
      //     //   {month: res.data.data.xAxis, value: res.data.data.series},
      //     // ]
      //   });
      //   // this.saleroomData = [
      //   //   {month: '1月', value: 816},
      //   //   {month: '2月', value: 542},
      //   //   {month: '3月', value: 914},
      //   //   {month: '4月', value: 781},
      //   //   {month: '5月', value: 355},
      //   //   {month: '6月', value: 796},
      //   //   {month: '7月', value: 714},
      //   //   {month: '8月', value: 1195},
      //   //   {month: '9月', value: 1055},
      //   //   {month: '10月', value: 271},
      //   //   {month: '11月', value: 384},
      //   //   {month: '12月', value: 1098}
      //   // ];
      // } else {
      //   // this.saleroomData = [
      //   //   {month: '1月', value: 1098},
      //   //   {month: '2月', value: 384},
      //   //   {month: '3月', value: 271},
      //   //   {month: '4月', value: 1055},
      //   //   {month: '5月', value: 1195},
      //   //   {month: '6月', value: 714},
      //   //   {month: '7月', value: 796},
      //   //   {month: '8月', value: 355},
      //   //   {month: '9月', value: 781},
      //   //   {month: '10月', value: 914},
      //   //   {month: '11月', value: 542},
      //   //   {month: '12月', value: 816}
      //   // ];
      // }
    },

  }

}
</script>

<style lang="scss" scoped>
.table_div{
  border-bottom: 1px solid #eeeeee;;
  margin-bottom: 20px;
}
.table_div_kuai{
  display: inline-block;
  font-size: 20px;
  font-weight: 500;
  color: #7b7b7b;
  margin: 15px;
  margin-bottom: 0;
  cursor: pointer;
  padding-bottom: 10px;
}
.table_div_kuai:hover{
  display: inline-block;
  font-size: 20px;
  font-weight: 700;
  color: #050505;
  border-bottom: 2px solid #FF9B05;
}
.table_div_kuai1{
  display: inline-block;
  font-size: 20px;
  font-weight: 700;
  color: #050505;
  border-bottom: 2px solid #FF9B05;
}
</style>
